export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `This application has been updated. ` +
      `Reload to display the latest version?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
  }
  
  console.log('gatsby-browser.js')
  
  // Register custom service worker
  export const onInitialClientRender = () => {
      console.log('onInitialClientRender')
    if ('serviceWorker' in navigator) {
        console.log('onInitialClientRender - load2')
      window.addEventListener('load', () => {
        console.log('onInitialClientRender - load')
        navigator.serviceWorker.register('/sw.js').then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch(error => {
          console.error('Service Worker registration failed:', error);
        });
      });
    }
  } 