import React from "react";
import Proptypes from "prop-types";
import classNames from "classnames";
import {Button} from "react-bootstrap";
import "./button-with-overlay.scss";

import { useState } from "react";

const ButtonWithOverlay = ({children, overlayText, className, disabled, throttleTime = 0, ...rest}) => {
  const [isThrottled, setIsThrottled] = useState(false);
  const c = classNames('button-with-overlay', className);
  const hasOverlayText = overlayText !== undefined && overlayText !== null;
  const buttonIsDisabled = disabled || hasOverlayText || isThrottled;

  const handleClick = (event) => {
    if (isThrottled) {
      event.preventDefault();
      return;
    }
    setIsThrottled(true);
    setTimeout(() => setIsThrottled(false), throttleTime);
    if (rest.onClick) {
      rest.onClick(event);
    }
  };

  return <Button {...rest} className={c} disabled={buttonIsDisabled} onClick={handleClick}>
    { hasOverlayText && <div className="button-with-overlay__overlay">{overlayText}</div> }
    {children}
  </Button>
}

ButtonWithOverlay.propTypes = {
  children: Proptypes.node.isRequired,
  overlayText: Proptypes.string,
  className: Proptypes.string,
}

export default ButtonWithOverlay;
