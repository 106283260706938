import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/database";
import { firebaseConfig, firebaseFunctionsDevelopmentConfig } from "../firebase-config";
import { isLocalNetwork, isPWA } from "../utilities/helper-functions";

const isOnClient = typeof window !== 'undefined';

let firebaseApp;
if (isOnClient) {
  firebaseApp = firebase.initializeApp(firebaseConfig);

  // Set auth persistence based on PWA status
  const auth = firebase.auth();
  if (isPWA()) {
    console.log('isPWA setting auth persistence to SESSION')
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }

  // Setup Firebase Functions Emulator if configured
  if (isLocalNetwork() && firebaseFunctionsDevelopmentConfig.shouldUseEmulatorLocally) {
    firebaseApp.functions().useEmulator(
      firebaseFunctionsDevelopmentConfig.host,
      firebaseFunctionsDevelopmentConfig.port,
    );
  }
}

export default firebaseApp;