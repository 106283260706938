import { gameCollection } from "../firebase";

export async function createClue(game, name) {
  const newId = `new-clue-${Date.now().toString()}`;

  await gameCollection().doc(game.id).update({
    [`clues.${newId}`]: {
      id: newId,
      name,
      content: '',
      answer: [],
    },
  });

  return newId;
}

export function updateClue(game, clue) {
  return gameCollection().doc(game.id).update({
    [`clues.${clue.id}`]: clue._rawClueData,
  });
}

export function cloneClue(game, clue) {
  const newId = `${clue.id}-clone`;
  const newHintFormat = (id) => `${id}-c`;
  const hintsInClue = clue._rawClueData?.masterCluesHints ?? [];
  const hintsInGame = hintsInClue.map(id => game._rawGameData.hints[id]);
  const clonedMasterCluesHints = (clue._rawClueData?.masterCluesHints ?? []).map(id => newHintFormat(id));
  const cloneHintsToBeWrittenToGame = {};
  hintsInGame.forEach(hint => {
    cloneHintsToBeWrittenToGame[`hints.${newHintFormat(hint.id)}`] = {...hint, id: newHintFormat(hint.id)};
  });

  return gameCollection().doc(game.id).update({
    ...cloneHintsToBeWrittenToGame,
    [`clues.${newId}`]: {
      ...clue._rawClueData,
      id: newId,
      name: `${clue.name} [clone]`,
      masterCluesHints: clonedMasterCluesHints
    },
  });
}

export function encodeClueAnswer(answer) {
  return answer ? encodeURI(window.btoa(answer)) : null;
}

export function decodeClueAnswer(code) {
  return window.atob(decodeURI(code));
}
